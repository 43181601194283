@media (min-width:1023px) and (max-width:1440px) {
    .login-main-page{
        justify-content: end !important;
    }

    .login-wrappers {
        transform: translateX(0) !important; 
    }

    .serverLoginImg{
        left: 0 !important;
    }

    .serverLoginImg > div{
        width: 300px !important;
    }

    .greycloudImg {
        /* left: 80%!important; */
        display: none;
    }

    /* .greycloudImg > div{
        width: 100px !important;
    } */

    .beeStyle > div{
        width: 600px !important;
    }

    .beeStyle{
        top: 14% !important;
        transform: rotateZ(358deg);
        left: -1% !important;
    }

    .cloudwhiteImg{
        left: 40% !important;
        top: 5% !important;
    }

    .clouduploadImg{
        top: 15% !important;
    }

    

    .clouduploadImg > div{
        width: 150px !important;
    }


    .logoHireClass{
        top: 10px !important;
        left: 0px !important;
    }
}

@media (min-width:992px) and (max-width:1194px) {
    .nav-header {
        width: 5rem;
    }
    .stickyfooter_hs {
        width: 100vw !important;
        left: 0 !important;
    }
}

@media only screen and (max-width: 767px) {
    .nav-header .brand-logo {
      padding-left: 1rem;
      padding-right: 0;
      justify-content: start;
    }
  
    /* .themeButton{
      display: none;
    } */
  
    .header-profile .header-info {
      display: none !important;
    }
  
    .header-content ul.header-right.navbar-nav {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mm-active {
        background: #fff;
    }
  }