.sideModalComponent{
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.251);
}

.sideModalComponentBox{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
   
    width:400px;
    background-color: white;

    overflow-y: auto;

    padding: var(--sideBarHSModal);
}

.closeHsModalRight{
    position: absolute;
    top: 1px;
    right: 10px;

    font-size: 16px;
    font-weight: 700;

    padding: var(--sideBarHSModal);
    color: var(--hsprimarycolor);

    cursor: pointer;
}
.closeHsModalLeft{
    position: absolute;
    top: 3px;
    left: 5px;

    font-size: 16px;
    font-weight: 700;

    padding: var(--sideBarHSModal);
    color: var(--hsprimarycolor);

    cursor: pointer;
}

.childrenSideModalHs{

}

@media only screen and (max-width: 600px) {
    .sideModalComponentBox {
        width:300px;
    }
}