@media (max-width:340px) and (max-height:600px) {
    .logoHireLogin{
        display: none !important;
    }
}

@media (min-width:1200px){
    .paymentInnerCard {
        max-width: 800px;
        width: 800px;
      }
      
}