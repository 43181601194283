
.boxlocation_hireserver {
    position: relative;
    min-width: 150px;
    height: 150px;
    border: 0.6000000238418579px solid #D6D6D6;
    margin: 10px;
}

.radiotabInput   {
    position: absolute;
    top: 18px;
    right: 5%;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 10px;
    pointer-events: none;
}

.tick_Mini_radio{
  position: absolute;
  top: 4px;
  left: 16px;
  width: 15px;
  height: 15px;
      display: none;
      border-radius: 10px;
      background: #24B7AF 0% 0% no-repeat padding-box;
      font-size: 9px;
      color: white;

     
  
  }

  .tick_defaultMini_radio{
    position: absolute;
    top: 4px;
    left: 16px;
    width: 15px;
    height: 15px;
    display: block;
    pointer-events: none;
    border: 0.6000000238418579px solid #D6D6D6;
    border-radius: 10px;
  }
  

  .radiotabInput:checked{
    background-color: red !important;
  }
  .radiotabInput:checked ~ .tick_Mini_radio{
    display: block !important;
  }
  .radiotabInput:checked ~ .tick_defaultMini_radio{
    display: none !important;
  }

  /* .checkbox_location_hireserver:checked ~ .tick_defaultMini {
    display: none;
}

.checkbox_location_hireserver:checked ~ .tick_Mini {
    display: block;
} */