.inputLabelUpContainer{
    position: relative;
}

.inputLabelUpInputLabel{
    position: absolute;
    top: 2px;
    left: 0;

    pointer-events: none;
    transform: translateY(60%);

    padding-left: 10px;

   
}

.inputLabelUpInput,.inputLabelUpInputLabel{
    transition: all .2s ease !important;
}

.inputLabelUpInputLabel::before{
    content: '*';
    position: absolute;
    top: 0;
    right: -10px;
    color: red;
    font-size: 12px;
}

.inputLabelUpInput{
    padding-left: 8px !important;
    padding-top: 10px !important;
}

.inputLabelUpInputLabel {
    color: var(--placeHolderColor);
    font-size: var(--placeHolderFontSize);
}

.inputLabelUpInput:focus ~ .inputLabelUpInputLabel{
    font-size: 12px !important;
    padding-left:8px;
    transform: translateY(2px);
    color: #7e7e7e;
}

.inputLabelUpInput.inputLabelValueThere ~ .inputLabelUpInputLabel{
    font-size: 12px !important;
    padding-left: 10px !important;
    transform: translateY(2px) !important;
    color: #7e7e7e;
}