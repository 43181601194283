@media (max-width:1023px) {
    
    .billingFromDiv{
        width: 100% !important;
    }
    .overviewMachineDetails{
        flex-direction: column;
    }
    .nextDueBtnDiv {
        flex-direction: row !important;
    }
    .greycloudImg,.beeStyle,.serverLoginImg,.cloudwhiteImg,.clouduploadImg{
        display: none  !important;
    }
    .login-main-page{
        justify-content: center !important;
    }
    .login-wrappers {
        transform: translateX(0);
    }
    .beebox{
        display: none !important;
    }


    /* notifation */
    .sideModalNoRoute{
        width: 100vw !important;
        z-index: 6 !important;
    }

    .loginSkeleton{
        transform: translateX(0) !important;
    }

    .loaderWaiting {
        left: 50% !important;
    }

    .logoHireClass{
        display: none !important;
    }

    .dataTablesCard {
        overflow: auto;
      }

      .billingFromDiv{
        width: max-content;
      }

    .contentLocation  .tenureCardBoxWidth {
        width: 150px !important;
    }
      
}