@media (max-width:425px) {
  
    .billingFormFlex{
        flex-direction: column;
    }

    .overviewMachineDetailsChild {
        flex-direction: column;
    }


    /* logo */
    .logomt{
       margin-top: 11px !important;
    }

    .logoHireLogin{
        left: 40px !important;
    }

    .new-account{
    font-size: 12px !important;   
    }
}




@media only screen and (max-width: 480px){
    .Toastify__toast-container {
        z-index: 9999 !important;
       
        position: fixed !important;
        padding: 4px !important;
        width: 320px !important;
        box-sizing: border-box !important;
        color: #fff !important;
    }

    .walletboxContainerAccount{
        flex-direction: column;
        gap: 10px;
    }

    .inputBorderDown input{
        padding: 4px 4px !important;
    }

    .Toastify__toast-container--top-center {
        top: 1em !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
    }

    .nav-header .brand-logo{
        padding-left: 0px !important;
    }

    [data-theme-version="dark"]  .nav-header .nav-control{
        background: transparent;
      }

      .brand-title{
        padding-left: 22px;
      }
    

      .deznav .deznav-scroll{
        -webkit-box-shadow: 4px 0 11px -7px rgb(60 146 86);
-moz-box-shadow: 4px 0 11px -7px rgb(60 146 86);
box-shadow: 4px 0 11px -7px rgb(60 146 86);
      }


      .tenureCardBoxWidth{
        width: 146px !important;
      }

    .login-wrappers{
        height: 95vh !important;
    }

    .login-main-page:after{
        background: white !important;
    }

    .authincation-content{
        background: white !important;
    }

    .maxWidthLoginContainer .col-xl-12,.auth-form-1,.loginBox{
        height: 100% !important;
    }
    

    .loginBox {
        display: flex;
        flex-direction: column;
        gap: 30px;
        position: relative;
    }

    .authincation-content{
        box-shadow: none;
    }

    .containerLoginSkeletonScreen .new-account {
        position: absolute;
        bottom: 10%;
    }
   
}


@media only screen and (max-width:360px){
    .wallHeaderItem{
        display:none !important;
    }
    .notification_dropdown{
        display:none !important;
    }
    .nameHeaderInfo{
        max-width: 100px;
      
    }
    .nav_header{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    
}