.spacesHs{
    margin: 10px;
}

.SpacerHS_TWO{
    margin: 20px;
}

.SpacerHS_ONE{
    margin: 5px;
}