[data-theme-version="dark"] .hiddenShowMiniCard:hover > div {
  color: black !important;
}
[data-theme-version="dark"] .themeButton {
  background-color: transparent !important;
  color: white !important;
}

[data-theme-version="dark"] .themeButton {
  background-color: transparent !important;
  color: white !important;
}

[data-theme-version="dark"] .select__menu * div {
  color: black !important;
}

[data-theme-version="dark"] .btn-hslineargradient:disabled {
  background: #c89b9b !important;
}

[data-nav-headerbg="color_hs1"][data-theme-version="dark"] #main-wrapper.menu-toggle .nav-header {
  background-color:#1e2746 !important;
}

[data-nav-headerbg="color_hs1"][data-theme-version="dark"]
  .HeaderCreateButtonDropDownItem
  .dropdown-item:active
  p {
  color: white !important;
}

[data-sibebarbg="color_hs1"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_hs1"] .deznav {
  background: var(--hslineargradient);
}

[data-theme-version="dark"] .dataTables_paginate .pagination .page-item {
  /* background-color: #000; */
  color: white;
}

[data-theme-version="dark"] .dropdownlistCard > button {
  border-color: transparent !important;
}

[data-theme-version="dark"]
  .headerProfile
  .dropdown-item
  .dropdown-item.ai-icon:active
  > span {
  color: white !important;
}

/* dark theme */
[data-theme-version="dark"] div {
  color: white !important;
}

[data-theme-version="dark"] .selectClassnameReactive > div {
  background: var(--darkBgPrimary) !important;
}

[data-theme-version="dark"] .sideModalNoRoute,
[data-theme-version="dark"] .passwordBoxHS {
  background: var(--darkBgPrimary) !important;
}

[data-theme-version="dark"] .form-control:disabled {
  color: black !important;
}

[data-theme-version="dark"] .select__control {
  background: var(--darkBgPrimary) !important;
}

[data-theme-version="dark"] .css-1rhbuit-multiValue {
  background: var(--darkBgPrimary) !important;
  border: 1px solid white;
}

[data-theme-version="dark"] .navbar-nav li .dropdown button,
[data-theme-version="dark"] .navbar-nav li .dropdown button div {
  color: white !important;
  /* background: var(--darkBgPrimary) !important ; */
}

/* svg{
    fill:var(--darkBgPrimary) !important ;
    color:red !important
  } */

[data-theme-version="dark"] .nav-item.dropdown a {
  background: var(--darkBgPrimary) !important ;
  color: white !important;
}

[data-theme-version="dark"] svg:not(#Group_6352),
[data-theme-version="dark"] svg path {
  fill: white !important;
}

[data-theme-version="dark"]
  .navbar-nav
  li
  .header-profile.headerProfile
  > button {
  background: transparent !important;
}

[data-theme-version="dark"] li .headerProfile button > a > .header-info {
  background: transparent !important;
  color: white !important;
}

[data-theme-version="dark"] li .headerProfile button > a > .header-info > * {
  color: white !important;
}

[data-nav-headerbg="color_hs1"][data-theme-version="dark"] .nav-header {
  background-color: transparent !important;
}

[data-sibebarbg="color_hs1"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_hs1"] .deznav {
  background-color: var(--darkBgPrimary) !important;
}

[data-theme-version="dark"] .deznav ul {
  background-color: var(--darkBgPrimary) !important;
}

[data-sibebarbg="color_hs1"][data-theme-version="dark"] .deznav {
  background: var(--darkBgPrimary) !important;
}

[data-theme-version="dark"] a.text-dark:hover,
a.text-dark:focus {
  color: white !important;
}

[data-theme-version="dark"] .footer {
  background: var(--darkBgPrimary) !important;
}

/* content-body */
[data-theme-version="dark"] .content-body .container-fluid {
  background: var(--darkBhSecondary) !important;
}

[data-theme-version="dark"] .dataTables_wrapper {
  background: var(--darkBhSecondary) !important;
}

[data-theme-version="dark"] .mdb-datatable-filter,
[data-theme-version="dark"] .mdb-datatable-filter > input {
  background: black !important;
  color: white !important;
}
[data-theme-version="dark"] .alertBackground {
  background: var(--darkBgPrimary) !important;
}

[data-theme-version="dark"] .deznav .metismenu > li:hover > a {
  color: white !important;
  background: var(--hslineargradient) !important;
}

[data-theme-version="dark"] .deznav .metismenu > li:hover svg path {
  fill: white !important;
}

[data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg path {
  fill: white !important;
}

[data-theme-version="dark"] .page-link > span {
  color: white !important;
}

[data-theme-version="dark"] .form-control:disabled {
  background-color: rgb(74, 151, 180) !important;
  border-radius: var(--borderRadiusHS);
}

[data-theme-version="dark"]
  .table-dropdown.dropdown-toggle.btn.btn-true
  > svg
  circle {
  fill: white !important;
}

[data-theme-version="dark"] .emptyListContainer,
[data-theme-version="dark"] .content-body .container-fluid,
[data-theme-version="dark"]
  .emptyDatabaseContainer
  [data-theme-version="dark"]
  .container-fluid
  .emptyListContainer {
  background: var(--darkBhSecondary) !important;
}

[data-theme-version="dark"] .contentContainer > div,
[data-theme-version="dark"] .emptyListContainer,
[data-theme-version="dark"] .emptyDatabaseContainer {
  color: white !important;
}

[data-theme-version="dark"] .emptyDatabaseContainer {
  background: var(--darkBhSecondary) !important;
}

[data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li.mm-active
  > a {
  background: var(--hslineargradient) !important;
  color: white;
}

[data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li:hover {
  color: white !important;
}

[data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li.mm-active
  > a
  i {
  color: white !important;
}

[data-theme-version="dark"] .header {
  background-color: var(--darkBhSecondary) !important;
}

[data-theme-version="dark"] .dropdown-item .item_list > * {
  color: white !important;
}

[data-theme-version="dark"] .stickyfooter_hs {
  background-color: var(--darkBGThird);
}

[data-theme-version="dark"] .stickyfooter_hs * {
  color: white;
}

[data-theme-version="dark"] .blocksInputControl {
  background: var(--darkBgPrimary) !important;
}

[data-theme-version="dark"] .blocksInputControl > * {
  color: white !important;
}

[data-theme-version="dark"] [data-name="MINUS_BTN"],
[data-theme-version="dark"] [data-name="PLUS_BTN"] {
  border: 0.5px solid #0000004a;
}

[data-theme-version="dark"] [data-name="MINUS_BTN"] > span {
  color: black !important;
}

[data-theme-version="dark"] [data-name="PLUS_BTN"] > span {
  color: black !important;
}

[data-theme-version="dark"] span {
  color: white !important;
}

[data-theme-version="dark"] .boxlocation_hireserver {
  border: none !important;
}
[data-theme-version="dark"] .boxlocation_hireserver {
  background: var(--darkBgPrimary) !important;
}

[data-theme-version="dark"] .backbroundoverlay:before {
  opacity: 0.1;
}

[data-theme-version="dark"] p {
  color: white !important;
}

[data-theme-version="dark"] button {
  background: var(--hslineargradient) !important;
  color: white !important;
  border: none;
}
[data-theme-version="dark"] .passwordiconhs {
 color: white !important;
}
[data-theme-version="dark"] .bg-lightgrey-100 {
  background: var(--darkBgPrimary) !important;
  color: white !important;
}
[data-theme-version="dark"] .tabsSelection > nav > a {
  background: transparent !important;
  color: white !important;
}

[data-theme-version="dark"] .tabsSelection .nav-tabs {
  border-bottom: none !important;
}

[data-theme-version="dark"] .createButtonDropdown button.dropdown-toggle {
  background: var(--hslineargradient) !important;
}

[data-theme-version="dark"] .listDropdownHS button {
  background: transparent !important;
}

[data-theme-version="dark"] .snapshotTable,
[data-theme-version="dark"] .snapshotTable .table-body tr td {
  color: white !important;
}

[data-theme-version="dark"] table.dataTable tbody td {
  color: white !important;
}

[data-theme-version="dark"] canvas {
  color: white !important;
}



[data-theme-version="dark"] .mm-active .nav-text {
  color: white;
}

/* [data-nav-headerbg="color_hs1"][data-theme-version="dark"] .nav-header {
  background-color: var(--darkBgPrimary) !important;
} */

[data-theme-version="dark"] .themeButton {
  background-color: transparent !important;
  color: white !important;
}

[data-theme-version="dark"] .select__menu * div {
  color: black !important;
}

/* [data-nav-headerbg="color_hs1"][data-theme-version="dark"] .nav-header {
  background-color: var(--darkBgPrimary) !important;
} */


[data-theme-version="dark"] input::placeholder{
    color: white !important;
}

[data-theme-version="dark"] .select--is-disabled .select__control--is-disabled{
    background-color: rgb(74, 151, 180) !important;
}

[data-theme-version="dark"] .inputLabelUpInputLabel {
    color: white !important;
}

[data-theme-version="dark"] button.btn-secondary {
    background: #ff00007a  !important;
    color: white !important;
}

[data-theme-version="dark"] .hs-signin-btn {
    border: none !important;
}

[data-theme-version="dark"] .form-control:disabled {
    color: white !important;
}

[data-theme-version="dark"]  .btn-danger{
    border:none !important
}

[data-theme-version="dark"] .modal-content {
    background: #162c26 !important;
}

[data-theme-version="dark"] .boxlocation_hireserver .dropdown-item{
    background: #1e2746;
    color: white !important;  
}
[data-theme-version="dark"]  .dropdownlistCard > .dropdown-menu{
    box-shadow: 0px 3px 6px #376844 !important;
}

[data-theme-version="dark"]  .dropdownlistCard > .dropdown-menu > a:last-child{
    border-bottom:none !important
}

