/* .machineCardDashboard{

  
    border-radius: var(--borderRadiusHS) !important;
    padding: 2rem;
    margin: 0 !important;

} */

.cardValueDashboard{
    font-size: 20px;
}

.iconmachinecard{
    /* background: #7bc89a57; */
    border-radius: 25px;
    padding: 0.4rem;
    width: 50px;
    height: 50px;
    border:none;

    box-shadow: var(--box-shadow);
}

.borderNeed{
    border-left: 1px solid var(--borderBottom);
    border-right: 1px solid var(--borderBottom);    
}