.beebox {
  position: absolute;
  bottom: 1%;
  right: 10%;
  /* left: -200px; */

  height: 23px;
  width: 95vw;
  /* 
    background-image: url("../../../../../images/hireserver/imgs/dottedline.svg"); */
}

.beestandfooter {
  position: absolute;
  top: -317%;
  right: -50px;
}
