/* .timelineBorder{
    position: relative;
    transform: translateX(50%);
}

.timelineBorder::after{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
 
    height: 100%;

    border: 1px dashed;
} */

.timeline_icon_wrap {
    height: 20px;
    width: 20px;
    display: flex;
    /* background: rgba(31, 35, 39, 0.5); */
    color: #fff;
    border-radius: 50%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.timelineBorderClass{
    width: 1px;
    border-left: 1px dashed;
}