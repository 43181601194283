.btnAnimate{
    transition: all .2s ease !important;
    display: flex !important;
    justify-content: center;

    cursor: pointer;
}

/* .btnAnimate:hover{
   transform: translateY(5px);
} */