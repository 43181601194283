:root {
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --hslineargradient: linear-gradient(180deg, #33704e, #325d61);
  /* --hslineargradient: linear-gradient(to bottom, #068d8a, #37b5ae); */
  --hsprimarycolor: #068d8a;
  --secondaryColor: #37b5ae;
  --gradientColor:#33704e;
  --theme-border-radius: 0.25rem;
  --headerHeight: 7.5rem;
  --borderRadiusHS: 0.25rem !important;
  /* sidebarModalHS */
  --sideBarHSModal: 15px;
  --tableArrow:'▼';
  --placeHolderFontSize: 13px !important;
  --placeHolderColor:#7e7e7e !important;

 --hs-grey: #f7f7f7;
  /* dark theme */
  --darkBgPrimary: #1e2746;
  --darkBhSecondary: #201a38;
  --darkBGThird: #02081e;

  --which-width: 14px;
  --bgLightGreen: #61d2c529;

  --borderBottom: #e8f1f1;

  --disableBgColor: #d8d8d836;

  --hover-dropitem: #f4f5f8;

  --hs-light-grey:#9a9898;

  --box-shadow:rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/* html{
  height: 100%;
}

body{
  height: 100%;
} */

input:disabled{
  border: 1px solid;
}

.dataTables_paginate .pagination .page-item .page-link{
  
  /* padding: 0.55rem 1rem !important; */
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;

}

.dataTables_info,.dataTables_paginate{
  padding: 0 40px !important;

}

.hs-boxshadow{
  box-shadow:var(--box-shadow)
}

.border-bottum-hs{
  border-bottom: 1px solid var(--hs-grey);
}

body {
  overflow-y: overlay;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.hs-lightgrey{
  color: var(--hs-light-grey);
}

input::placeholder{
  color: var(--placeHolderColor);
  font-size: var(--placeHolderFontSize);
}

.sk-three-bounce-loading{
  background: linear-gradient( 185deg, rgba(244, 252, 189, 1) 21%, rgba(203, 237, 202, 1) 58%, rgba(189, 233, 210, 1) 100% );
}

.btn-hslineargradient:disabled {
  background: gainsboro !important;
  cursor: auto;
}

.tox-statusbar__branding {
  display: none !important;
}

.hs-grey{
  background-color: var(--hs-grey);
}

.font-09{
  font-size: 0.9rem;
}


.hsButton-bg {
  background: var(--hslineargradient);
}
body > iframe {
  pointer-events: none;
}

.mw-600p {
  max-width: 600px !important;
}

.w-80p{
  width: 80px;
}

.select2-dropdown {
  z-index: 9001;
  
}

/* .selectClassnameReactive .select2-dropdown  > *:hover{
  color: white !important;
} */

.form-control:disabled {
  background-color: var(--disableBgColor) !important;
}

.fig_hs_pointer {
  cursor: pointer !important;
}

.mainContainerHS {
  margin-bottom: 100px;
}

.hsBack {
  color: var(--hsprimarycolor) !important;
  text-decoration: none !important;
}

.linkRouter_HS {
  color: var(--hsprimarycolor) !important;
  text-decoration: none !important;
}

.border-radius-hs {
  border-radius: var(--theme-border-radius) !important;
}

.light_borderColor {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

label {
  padding-top: 0;
}

.hs_borderRadius-4 {
  border-radius: var(--borderRadiusHS) !important;
}

.bg-hs {
  background-color: #37b5ae !important;
}

.hs_secondaryColor {
  color: var(--secondaryColor);
}

.validFormInputSelect2 .select__control {
  border-radius: var(--borderRadiusHS);
}

.select__control--is-disabled {
  background-color: var(--disableBgColor) !important;
}

.hs_text-light {
  color: #7e7e7e;
}

.btn-outline-primary {
  color: var(--hsprimarycolor) !important;
  border-color: var(--hsprimarycolor) !important;
}

.btn-outline-primary:hover {
  background-color: var(--hsprimarycolor) !important;

  color: white !important;
}

.hs_sub_font {
  font-size: 14px !important;
}

.font-7 {
  font-size: 7px !important;
}

.font-10 {
  font-size: 10px !important;
}

.hs_heading_font {
  font-size: 16px !important;
}

svg {
  fill: white;
}

a {
  color: #2575d0;
}

a:hover {
  color: #2575d0;
}

.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-12 {
  font-size: 12px !important;
}

.font-80 {
  font-size: 80px;
}

.w-35 {
  width: 35%;
}

.w-35p {
  width: 35px !important;
}

.w-35h {
  height: 35px !important;
}

.h-80 {
  height: 90% !important;
}

.h-150p {
  height: 150px !important;
}

.w-200p{
  width: 200px !important;
}

.font-30 {
  font-size: 30px !important;
}

.text-lightgrey {
  color: #abadaf;
}
.text-darkgrey {
  color: #606469;
}

.bg-lightgrey-100 {
  background-color: #f7f7f7;
}

.bg-primary {
  background-color: var(--hsprimarycolor) !important;
}

.text-primary {
  color: var(--hsprimarycolor) !important;
}
.border-primary {
  border: 1px solid var(--hsprimarycolor) !important;
}

.font-21 {
  font-size: 21px;
}

.font-18 {
  font-size: 18px;
}

.font-15 {
  font-size: 15px !important;
}

.font-5 {
  font-size: 5px !important;
}

.h-49 {
  height: 46px !important;
}

.w-212p {
  width: 212px !important;
}

.select__placeholder {
  color: black !important;
}

.select__control {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}
.border-radius-2 {
  border-radius: 2px !important;
}

.line-through {
  text-decoration: line-through;
  text-decoration-color: var(--hsprimarycolor);
}

.support-card:hover .support-card-title,
a:hover {
  text-decoration: underline;
}

.no-word-break{
  white-space: nowrap;
}

.nav-item{
  white-space: nowrap;
}

.softwareTabsOver{
  overflow-x: auto;
  overflow-y: hidden;
}

/* body{
      font-family: var(--font-family-sans-serif) !important;
  } */
body {
  color: #000 !important;
}

.icon_wallet {
  font-size: 22px;
  padding: 8px !important;
}

.routerLinkTag,
.routerLinkTag:hover {
  color: black;
  text-decoration: none;
}

.pointerEventNone {
  pointer-events: none;
}

.form-text {
  margin-top: 0px !important;
}

.imgFullClass > .imgFullClassInner {
  width: 100% !important;
  height: 100% !important;
}


[data-nav-headerbg="color_hs1"] .nav-header {
  background: #33704e;
}



[data-headerbg="color_1"] .nav-header .hamburger.is-active .line,
[data-headerbg="color_1"] .nav-header .hamburger .line {
  background: var(--hslineargradient) !important;
}

[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li.mm-active
  > a
  i {
  color: var(--hsprimarycolor);
}

[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li.mm-active
  > a {
  color: var(--hsprimarycolor);
  font-size: 15px;
  font-weight: 500;
  /* background: none; */
}
[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li
  > a {
  font-size: 15px;
  font-weight: 400;
  padding: 15px 25px 15px 25px;
  margin-bottom: 10px;
}

[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li
  > a
  i {
  font-weight: 300;
}
.deznav .metismenu > li:hover > a,
.deznav .metismenu > li:focus > a {
  background: #fff;
  font-weight: 500 !important;
  color: var(--hsprimarycolor);
}
.deznav .metismenu a {
  color: white;
  /* color: hsla(0,0%,100%,.7); */
}

.dashboardImgIcon {
  width: 16px;
  height: 24px;
}

[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #51a9a7;
}

[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a
  i,
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a
  i {
  color: white;
}

#main-wrapper.menu-toggle .header .header-content {
  padding-left: 5.3125rem;
}

.bg-primary {
  background-color: var(--hsprimarycolor) !important;
}

.header-left .dashboard_bar {
  font-size: 20px;
}

/* h2{
  font-size: 1.5rem !important;
  margin-bottom: 0;
  } */
.login-wrapper .social-icons li a {
  color: var(--hsprimarycolor);

  border: 1px solid var(--hsprimarycolor);
}
.login-wrapper .social-icons li a:hover {
  background: #fff;
  color: #000;
}

.login-main-page .login-wrapper {
  background: white;
}

.login-aside-right {
  background-color: var(--hslineargradient);
  width: 100%;
}

.text-primary {
  color: var(--hsprimarycolor) !important;
}
.border-primary {
  border: 1px solid var(--hsprimarycolor) !important;
}

.hs-signin-btn {
  background: var(--hslineargradient);
  /* background-color: #ff8000; */
  color: #fff;
  /* box-shadow: 1px 0px 5px 2px rgba(6,141,138,0.75);
  -webkit-box-shadow: 1px 0px 5px 2px rgba(6,141,138,0.75);
  -moz-box-shadow: 1px 0px 5px 2px rgba(6,141,138,0.75); */

  /* box-shadow: 0 4px 8px 0 rgba(6,141,138,0.24);  */
  /* box-shadow: 0 4px 8px 0 rgb(255 128 0 / 24%); */
}

.login-main-page {
  display: flex;
  position: relative;
  padding: 0;
  height: 100%;
}

.bg-login-right {
  background: url(../images/login-right.png) no-repeat right;
}

.login-bg-img {
  max-width: 50%;
}

.login-main-page:after {
  background: white;
  background: rgb(244, 252, 189);
  background: linear-gradient(
    185deg,
    rgba(244, 252, 189, 1) 21%,
    rgba(203, 237, 202, 1) 58%,
    rgba(189, 233, 210, 1) 100%
  );
}

.border-bottom-hs {
  border-bottom: 5px solid var(--hsprimarycolor) !important;
}
.border-bottom-hs-back {
  border-bottom: 5px solid var(--bgLightGreen);
}

.banner_img_down_container_hs {
  padding: 50px;
}

.icon_backGround_header {
  background: #f4f4f4;
  border-radius: 2rem;
}

.login-wrapper {
  position: absolute;
  background: white;
  padding: 30px;
  height: 100%;
  display: flex;
}

.beeStyle {
  position: absolute;
  top: 150px;
  left: 0;
  z-index: 9999;
}

.serverLoginImg {
  position: absolute;
  bottom: 0;
  left: 150px;
  z-index: 9999;
}

.clouduploadImg {
  position: absolute;
  top: 30%;
  left: 2%;
  z-index: 9999;
}

.cloudwhiteImg {
  position: absolute;
  top: 10%;
  left: 40%;
  z-index: 9999;
}

.greycloudImg {
  position: absolute;
  bottom: 10%;
  left: 40%;
  z-index: 9999;
}

.logoHireLogin {
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 999;
}

.forgotPasswordLoginWrapper {
  min-height: max-content;
}

.removeHighLightBtn.hs-signin-btn:hover {
  color: white !important;
}

.hs-signin-btn:hover {
  color: white !important;
}

.removeATagStylesDefault,
.removeATagStylesDefault:hover {
  text-decoration: none;
  color: black;
}

@media (max-width: 575.98px) {
  .login-wrapper {
    width: 100%;
  }
  .login-wrappers {
    transform: translateX(0);
  }
}

@media (min-width: 992px) {
  .login-wrapper {
    width: 500px;
    height: max-content;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 0px 6px 8px #00000054;
    border-radius: 6px;
  }

  /* .login-wrappers{
    transform: translateX(-40%);
  } */
}

.authincation-content {
  border-radius: var(--borderRadiusHS) !important;
}

.mm-active svg {
  fill: var(--hsprimarycolor);
}

.metismenu > li:hover svg {
  fill: var(--hsprimarycolor);
}

thead[data-test="table-foot"] {
  display: none;
}

.pagination .page-item.active .page-link {
  background-color: var(--hsprimarycolor);
  border-color: var(--hsprimarycolor);
  box-shadow: none;
}

.pagination .page-item:last-child .page-link:hover{
  background-color: transparent !important;
}

.pagination .page-item .page-link:hover {
  background-color: var(--secondaryColor);
  border-radius: 50%;
  border-color: var(--secondaryColor);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--hsprimarycolor);
  color: white !important;
}

.dropdown-item:active div:not(.drop_icon),
.dropdown-item:active p {
  color: white !important;
}

#preloader .sk-child {
  background-color: var(--hsprimarycolor) !important;
}

.instancecreatebtn {
  border: none;
  background: var(--hslineargradient);
  border-radius: 50px;
  color: white;
  padding: 10px;
  font-size: 16px;
  padding: 10px 30px;
  cursor: pointer;
}

.menu-toggle .brand-logo-mobile{
  width: 200px;
  margin: 1rem;
}

.menu-toggle .mobileImgLogoMain{
  display: block;
}

.mobileImgLogoMain{
  display: none;
}

/* .form-control{
      height: auto;
  } */

.custom-select:focus {
  border-color: var(--hsprimarycolor);
  color: var(--hsprimarycolor);
}

.deznav .metismenu > li.mm-active > a svg,
.deznav .metismenu > li:hover > a svg {
  color: var(--hsprimarycolor);
}

.deznav .metismenu > li > a svg {
  color: white;
  margin-right: 15px;
}

/*----------------- Added by Rahul------------------------- */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.profile-img {
  width: 100px;
  height: 100px;
}

.update-btn {
  width: 210px;
}

.profile-tab .card-title {
  font-size: 16px;
}

.profile-tab .card-text {
  font-size: 14px;
  line-height: 1.5;
}

.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-12 {
  font-size: 12px;
}

.support-search-bar input {
  padding-left: 30px;
}
.support-search-bar input::placeholder {
  color: #abadaf;
}
.support-search-bar {
  position: relative;
}

.help-cards i {
  color: #2575d0;
}

.search-icon {
  position: absolute;
  padding-left: 10px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.help-cards p {
  font-size: 14px;
}

.supportheaderbg {
  background: #1d262f;
  background-image: url(http://hireserver.bizgaze.com/images/home-page/comb.png);
}

/*------------------------------------ responsive-css-------------------------------- */
/* / Small devices (landscape phones, 576px and up) / */
@media (max-width: 576px) {
  .profile-update {
    justify-content: flex-start !important;
    margin-top: 1.2rem;
  }
}

@media (min-width: 576px) {
  .w-md-75 {
    width: 75%;
  }

  .w-md-50 {
    width: 50%;
  }
}

.text-darkgrey {
  color: #606469;
}

.d-gap-5 {
  gap: 5px;
}

.d-gap-10 {
  gap: 10px;
}

.d-gap-15 {
  gap: 15px;
}

.d-gap-20 {
  gap: 20px;
}

.d-gap-30 {
  gap: 30px;
}

.d-gap-40 {
  gap: 40px;
}

label {
  margin: 0;
}

.h-300p {
  max-height: 300px;
}

.w-280p {
  width: 280px;
}

.w-250p {
  width: 250px;
}

.w-200p {
  width: 200px;
}

.wx-200p {
  max-width: 200px;
}

.support-card {
  height: 100%;
}

.support-page .most-popular {
  border-right: 1px solid #eeeeee;
}


.infinite-scroll-component.row {
  overflow-x: hidden !important;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOutHs {
  display: block;
  animation-delay: 2s;
  animation: fadeOut 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

/* alertBar_hs */

.alertBar_hs.backgroundalertHsBarRed {
  background-color: red;
}
.alertBar_hs.backgroundalertHsBarGreen {
  background-color: #3bb78f;
}

.alertBar_hs {
  width: 7px;
  background-color: red;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  border-top-left-radius: var(--borderRadiusHS);
  border-bottom-left-radius: var(--borderRadiusHS);
}

.closeAlertButton {
  border-left: 1px solid var(--borderBottom);
}

.supportListTable [data-test="datatable-input"]{
  width:100%;
}

/*------------------------------------ responsive-css-------------------------------- */
/* / Small devices (landscape phones, 576px and up) / */
@media (max-width: 576px) {
  .profile-update {
    justify-content: flex-start !important;
    margin-top: 1.2rem;
  }
  .support-page .most-popular {
    border: none;
  }
}

@media (min-width: 576px) {
  .w-md-75 {
    width: 75%;
  }

  .w-md-50 {
    width: 50%;
  }
  .w-md-35 {
    width: 35%;
  }
}

.inputNumberChange_hireServer {
  font-size: 15px;
  border: 0.4000000059604645px solid #a2a2a2;
  border-radius: 5px;
}

.totalAvaWidget_hs {
  width: 100%;
  background: unset;
  outline: none;
  border: none;
  text-align: center;
  /* font-size: 25px; */
}

.control_block_hs {
  cursor: pointer;
  font-size: 20px;
}

/* Chrome, Safari, Edge, Opera */
.removenumbercontrols::-webkit-outer-spin-button,
.removenumbercontrols::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.removenumbercontrols[type="number"] {
  -moz-appearance: textfield;
}

.valueinstantname_hireserver {
  font-size: 20px;
}

.pointer_events_none {
  pointer-events: none;
}

.boxlocation_hireserver {
  position: relative;
  min-width: 150px;
  /* height: 150px; */
  height: max-content;
  border: 0.6000000238418579px solid #d6d6d6;
  margin: 10px;
}

.tick_defaultMini {
  z-index: 1;
}

/* .tick_Mini_radio{
  position: absolute;
  top: 7px;
  left: -10px;
  width: 20px;
    height: 20px;
    display: none;
    border-radius: 10px;
    background: #24B7AF 0% 0% no-repeat padding-box;
    font-size: 13px;
    color: white;
} */

.boxlocation_hireserver .dropdown-menu {
  z-index: 9;
}

.checkbox_location_hireserver {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 20px;
  height: 20px;
  border: none;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  pointer-events: none;
}

.tick_default {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 20px;
  height: 20px;
  display: block;
  pointer-events: none;
  border: 0.6000000238418579px solid #d6d6d6;
  border-radius: 10px;
}

.checkbox_location_hireserver:checked ~ .tick_default {
  display: none;
}

.checkbox_location_hireserver:checked ~ .tick {
  display: block;
}

.tick {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 20px;
  height: 20px;
  display: none;
  border-radius: 10px;
  background: #24b7af 0% 0% no-repeat padding-box;
  font-size: 13px;
  color: white;
}

/* tick Selectlabel sachin */
.tick_Selectlabel {
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.text_hsdecoration {
  text-decoration: underline;
}

.tick_Check_tick_Selectlabel {
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #24b7af 0% 0% no-repeat padding-box;
  font-size: 20px;
  color: white;
  display: none;
}

.tick_SelectlabelInput:checked ~ .tick_Selectlabel {
  display: none;
}

.tick_SelectlabelInput:checked ~ .tick_Check_tick_Selectlabel {
  display: block;
}
/* tick Selectlabel end sachin */

.contentLocation {
  pointer-events: none;
}

.contentLocation img {
  border: 0.6000000238418579px solid #dcdcdc;
}

.contentLocation h2 {
  font-size: 20px;
}

/* .deznav .metismenu > li:hover > a, .deznav .metismenu > li:focus > a{
      background: rgba(52,160,139,1);
      border-radius: 40px;
      box-shadow: 0px 0px 5px 0px rgb(1 38 26 / 20%);
      color: white;
  } */

/* .nav-header .brand-title{
      margin-left: 0; 
      max-width: 220px;
      margin-top: 0;
    } */

/* .nav-header,.deznav{
        width: 230px;
    } */

/* .content-body{
        margin-left: 230px;
    } */

/* .nav-header .brand-logo{
        padding: 0;
        justify-content: center;
    } */

/* .header .header-content */

@media screen and (max-width: 480px) {
  .emptyDatabaseContainer {
    width: auto !important;
  }
}

/* rahul css */
/*-------------- 26-5-22 added styles----------------- */
.tabsSelection .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: unset;
  border: none;
}
.tabsSelection .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--hsprimarycolor);
  border: none;
  border-bottom: 3px solid var(--hsprimarycolor);
}
.tabsSelection .nav-tabs .nav-link {
  border: none;
}
.tabsSelection a {
  color: #495057;
}
.tabsSelection .nav-tabs {
  border-bottom: 2px solid #dee2e6;
  width: max-content;
}
.tabsSelection .nav-link {
  padding: 10px 35px;
}
.tabsSelection .btn-primary {
  font-size: 12px;
  color: unset !important;
  background-color: unset !important;
  /* border-color: unset !important; */
  /* border: 0 !important; */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dropdownlistCard > button {
  border-radius: var(--borderRadiusHS) !important;
  border-color: #dee2e6 !important;
}

.dropdown-menu .dropdown-item:hover {
  background: #eee;
}

.tabsSelection .btn-primary:focus {
  color: unset !important;
  background-color: unset !important;
  /* border-color: unset !important; */
  /* border: 0 !important; */
}
.tabsSelection .btn-primary:active {
  color: unset !important;
  background-color: unset !important;
  /* border-color: unset !important; */
  /* border: 0 !important; */
}
.tabsSelection .border-bottom {
  border-bottom: 1px solid !important;
}

.boxlocation_hireserver .dropdown-item {
  background: white;
  color: black !important;
  font-size: 12px !important;
  text-align: left !important;
  border-bottom: 1px solid var(--borderBottom);
}

.boxlocation_hireserver .dropdown-item.active {
  color: var(--hsprimarycolor) !important;
  font-weight: 600;
}

.boxlocation_hireserver #dropdown-basic-button {
  text-align: left !important;
}

.dropdownlistCard > .dropdown-menu {
  padding: 0 !important;
  box-shadow: 0px 3px 6px #d2dae2;

  border-radius: var(--borderRadiusHS) !important;
}

.totalAvailable .img {
  height: 70px;
}
.locations .img {
  height: 60px;
  width: 60px;
  background: rgba(231, 250, 236, 0.5);
  border: 1px solid #eeeeee;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  margin-bottom: 1rem;
}

.locations img {
  padding: 8px;
  width: 100%;
  height: 100%;
}
.softwareImg img {
  height: 60px;
}
.softwareImg {
  height: 70px;
}
.btn-hsprimary {
  background: var(--hsprimarycolor);
  color: white;
  padding: 15px 85px;
}

.btn-hslineargradient {
  background: var(--hslineargradient);
  color: white;
  padding: 10px 85px;
  border: 0px;
  border-radius: 50px;
}
.btn-hsprimary:hover {
  color: #f7f7f7;
}

.rounded-btn-hs {
  border-radius: 61px;
  /* background: var(--secondaryColor); */
  width: 60px;

  border: 1px solid #00000014;
  cursor: pointer;
}
.dot {
  height: 15px;
  width: 15px;
  background-color: #17cf73;
  border-radius: 50%;
  margin-top: 5px;
  display: inline-block;
}
.copy_content {
  width: 38px;
  padding: 11px 9px 11px 9px;
  background: #f1f7fd;
  color: #198cff;
}
.lish_ssh {
  overflow-x: scroll;
  white-space: nowrap;
}
.access_feild {
  background-color: #f4f5f6;
}

.run_data {
  background: #fafafa;
}
.running_tabs .nav-tabs .nav-link:hover,
.running_tabs .nav-tabs .nav-link:focus {
  border: none !important;
  border-bottom: 0.2px solid var(--hsprimarycolor) !important;
}
.running_tabs .nav-tabs .nav-link.active {
  border-bottom: 0.2px solid var(--hsprimarycolor) !important;
  color: var(--hsprimarycolor) !important;
  border-top: none;
  border-left: none;
  border-right: none;
}

.storage_items li p {
  font-size: 16px;
  font-weight: 500;
  color: gray;
}
.storage_items_list li {
  font-size: 16px;
  font-weight: 500;
  color: gray;
}
.no-data {
  font-size: 16px;
}
/* .create_vol{
      background: var(--hslineargradient);
      border-radius: 18px;
  } */
.save_thresholds {
  background: var(--hslineargradient);
  border-radius: 18px;
  width: 75px;
}
.delete_instnace {
  background: var(--hslineargradient);
  border-radius: 18px;
  width: 83px;
}
.arrow-toggle img {
  width: 15px;
}
.App .btn-link .setting_name {
  color: var(--hsprimarycolor) !important;
}
.App .card-header {
  padding: 0px 0px 0px 10px !important;
}
.App .form-control {
  border: 1px solid var(--hsprimarycolor) !important;
}
.strength {
  height: 3px;
  width: 85px;
  background-color: #858585;
  display: inline-block;
}
.backup-dot {
  height: 36px;
  width: 85px;
  background-color: var(--hsprimarycolor);
  border-radius: 4px;
  display: inline-block;
}
.backup_dot {
  height: 18px;
  width: 18px;
  right: 0px;
  margin: 9px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}
.top-head {
  margin-top: 35px;
}
.setting_disk_detail {
  width: 20px;
  margin: 11px 0px 0px 10px;
}
.backups {
  width: 150px;
  height: 150px;
}
/* .enable_instnace{
      background: var(--hslineargradient);
      border-radius: 18px;
      width: 175px;
      margin: auto;
    } */
.backup-text {
  font-size: 16px;
  font-weight: 500;
  color: gray;
}
.backup-head {
  font-size: 20px;
}
.threshold-content {
  font-size: 14px;
  font-weight: 500;
  color: #757171;
}
.icon_col svg {
  fill: var(--hsprimarycolor);
  width: 160px;
  height: 160px;
  padding: 32px 12px 12px 12px;
}
.icon_bg {
  background-color: #f4f4f4;
  width: 170px !important;
  height: 170px !important;
  border-radius: 100px;
}
.text-dark-0 {
  color: #32363c;
}
.submit_btn {
  background: var(--hslineargradient) !important;
  border-radius: 30px !important;
  border-color: var(--hslineargradient) !important;
  padding: 8px 18px;
}
/* edit by kiran */
.firewall_form {
  z-index: 9999;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 30%;
}
.testclass {
  height: 100vh !important;
}
.firewall_form .form-control {
  border-radius: 0px !important;
  padding: 0px !important;
  border: 0.2px solid #d7dae3;
  height: 43px;
}
.create_firewall_btn {
  background: var(--hslineargradient) !important;
  border-radius: 30px !important;
}
.firwall_overlay {
  position: absolute;
  transition: all 0.2s;
  width: 251vw;
  height: 100%;
  right: 576px;
  background: #000;
  z-index: 1;
  opacity: 0.1;
  cursor: pointer;
}
.firewall-rule input[type="radio"] {
  height: 30px;
  width: 30px;
  vertical-align: middle;
}
.Marketplace .CommunityStack input[type="radio"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}
.Marketplace .btn-primary {
  color: rgb(119, 117, 117) !important;
  background-color: #fff !important;
  border-color: #fff !important;
  padding: 0px;
  font-size: 14px;
}
.app-info img {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 7%;
}
.Overview .card {
  box-shadow: none;
}
.header-right .dropdown-toggle.btn-primary {
  border: none;
  background: var(--hslineargradient);
  border-radius: 50px;
  color: white;
  padding: 10px;
  font-size: 16px;
  padding: 4px 10px 4px 10px;
  cursor: pointer;
}
.header-right .dropdown-menu.create {
  min-width: 25rem !important;
}
.header-right .drop_icon {
  width: 50px;
  color: #808080;
}
.header-right .dropdown-toggle:after {
  content: none !important;
}
.header-right .drop_name {
  line-height: inherit;
  color: #000 !important;
}
.drop_item:hover {
  background-color: rgb(250 246 246);
}
.drop_item:hover .drop_name {
  color: #068d8a;
  text-decoration: underline;
}
.drop_items a:hover {
  text-decoration: none !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: #068d8a;
}

table.dataTable tbody td {
  padding: 8px 20px;
}

/* end by kiran */
@media (max-width: 576px) {
  .instance_location {
    width: 100%;
  }
  .header-right .create.dropdown-menu {
    position: absolute;
    margin-left: -168px !important;
    top: 64px;
    min-width: 22rem !important;
  }
  .headername {
    display: none;
  }
  .nav-header {
    transition: all 0.3.2s ease;
  }
  .mm-active {
    background: #fff;
  }
  .mm-active .nav-text {
    color: #068d8a;
  }



  [data-sidebar-style="overlay"] .menu-toggle .nav-header {
    background: #33704e !important;
    /* width: 40%; */
  }

  
  .deznav {
    /* width: 40%; */
  }
  .hamburger.is-active .line:nth-child(1) {
    transform: translateY(4px) rotate(131deg);
  }
  .hamburger.is-active .line:nth-child(3) {
    transform: translateY(-5px) rotate(222deg);
  }
  .hamburger .line {
    margin-left: 0;
    margin-right: auto;
  }
  [data-headerbg="color_1"] .nav-header .hamburger .line {
    background: var(--hslineargradient) !important;
  }
  [data-headerbg="color_1"] .nav-header .hamburger.is-active .line {
    background: #fff !important;
  }
  [data-nav-headerbg="color_hs1"] .nav-header {
    background-color: #fff !important;
  }
  .nav_header {
    box-shadow: 0rem 1rem 1rem -1rem rgb(0 0 0 / 15%) !important;
  }
  .form-control {
    width: 100% !important;
  }
  .marketplaceimgcard {
    width: 100% !important;
  }
  .instance-table [data-test="datatable-table"] {
    overflow-x: scroll;
  }
  [data-headerbg="color_1"] .nav-header .hamburger.is-active .line {
    background: #fff !important;
  }
  #main-wrapper.menu-toggle .header .header-content {
    padding-left: 4px;
  }
  .nav-control {
    right: 1.2rem;
  }
  .table-flow {
    overflow-x: scroll;
  }
  .instance_table [data-test="datatable-table"] {
    overflow-x: scroll;
  }
  .detail-card {
    width: 100% !important;
  }
  .detail-right {
    display: none;
  }
  /* .content-body {
      overflow-x: scroll;
    } */
  .summary-list {
    flex-direction: column !important;
  }
  .addresses,
  .addresses .col {
    flex-direction: column !important;
    padding: 0px !important;
  }
  .addresses .threshold-content {
    overflow-x: scroll;
    white-space: nowrap;
  }
  .threshold-content {
    font-size: 11px;
    font-weight: 500;
    color: gray;
    padding: 4px !important;
  }
  .detail-card .form-control {
    width: 100% !important;
  }
  .setting-thres {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
  }
  .setting-thres .border {
    width: 100px !important;
  }
  .running_tabs .nav-tabs {
    overflow-x: scroll;
    padding-bottom: 3px;
    overflow-y: hidden;
  }
  .running_tabs .nav-tabs .nav-link {
    line-height: 1;
    padding: 1rem 1rem;
    white-space: nowrap;
  }
  .save_thresholds {
    width: 68px;
  }
  .delete_instnace {
    width: 78px;
  }
  /* edit by kiran */
  .firewall_form {
    max-width: 80%;
  }
  .firewall_btns {
    justify-content: space-around !important;
  }
  .firewall_btns .text-blue {
    padding: 3px 0px !important;
  }
  /* end by kiran */
}
@media (min-width: 577px) and (max-width: 768px) {
  .hamburger.is-active .line:nth-child(1) {
    transform: translateY(4px) rotate(131deg);
  }
  .hamburger.is-active .line:nth-child(3) {
    transform: translateY(-5px) rotate(222deg);
  }
  .hamburger .line {
    margin-left: 0;
    margin-right: auto;
  }
  [data-headerbg="color_1"] .nav-header .hamburger .line {
    background: var(--hslineargradient) !important;
  }
  [data-nav-headerbg="color_hs1"] .nav-header {
    background-color: #fff !important;
  }

 

  .nav-header {
    transition: all 0.3.2s ease;
  }
  
  .mm-active .nav-text {
    color: #068d8a;
  }
  [data-sidebar-style="overlay"] .menu-toggle .nav-header {
    background: var(--gradientColor) !important;
    width: 50%;
  }
  /* .deznav {
    width: 50%;
  } */
  .hamburger.is-active .line:nth-child(1) {
    transform: translateY(4px) rotate(131deg);
  }
  .hamburger.is-active .line:nth-child(3) {
    transform: translateY(-5px) rotate(222deg);
  }
  .hamburger .line {
    margin-left: 0;
    margin-right: auto;
  }
  .form-control {
    width: 100% !important;
  }
  [data-headerbg="color_1"] .nav-header .hamburger.is-active .line {
    background: #fff !important;
  }
  #main-wrapper.menu-toggle .header .header-content {
    padding-left: 4px;
  }
  .nav-control {
    right: 1.2rem;
  }
  .instance-table [data-test="datatable-table"] {
    overflow-x: scroll;
  }
  .header .header-content {
    padding-left: 4px;
    padding-right: 0.938rem;
  }
  .detail-card {
    width: 100% !important;
  }
  .detail-card {
    width: 100% !important;
  }
  .detail-card .form-control {
    width: 100% !important;
  }
  .addresses,
  .addresses .col {
    flex-direction: column !important;
    padding: 0px !important;
  }
  .addresses .threshold-content {
    overflow-x: scroll;
    white-space: nowrap;
  }
  .table-flow {
    overflow-x: scroll;
  }
  .setting-thres {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
  }
  .setting-thres .border {
    width: 100px !important;
  }
  .save_thresholds {
    width: 68px;
  }
  .delete_instnace {
    width: 78px;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  [data-sidebar-style="overlay"] .menu-toggle .nav-header {
    background: #33704e  !important;
    width: 18rem;
  }
  .nav-header {
    transition: all 0.3.2s ease;
  }
  .mm-active {
    background: #fff;
  }
  .mm-active .nav-text {
    color: #068d8a;
  }
  [data-headerbg="color_1"] .nav-header .hamburger.is-active .line {
    background: #fff !important;
  }
  [data-headerbg="color_1"] .nav-header .hamburger .line {
    background: var(--hslineargradient) !important;
  }
  [data-nav-headerbg="color_hs1"] .nav-header {
    background-color: #fff !important;
  }
  #main-wrapper.menu-toggle .header .header-content {
    padding-left: 4px;
  }
  .nav-control {
    right: 1.2rem;
  }
  .instance-table [data-test="datatable-table"] {
    overflow-x: scroll;
  }
  .detail-card {
    width: 100% !important;
  }
  .detail-card .form-control {
    width: 50% !important;
  }
  .table-flow {
    overflow-x: scroll;
  }
  .addresses,
  .addresses .col {
    flex-direction: column !important;
    padding: 0px !important;
  }
  .addresses .threshold-content {
    overflow-x: scroll;
    white-space: nowrap;
  }
  .setting-thres {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
  }
  .setting-thres .border {
    width: 100px !important;
  }
  .save_thresholds {
    width: 68px;
  }
  .delete_instnace {
    width: 78px;
  }
  /* .header-left .dashboard_bar {
    margin-left: 80px;
  } */
}
@media (min-width: 993px) and (max-width: 1198.98px) {
  [data-sidebar-style="overlay"] .menu-toggle .nav-header {
    background: #33704e !important;
    width: 18rem;
  }
  [data-headerbg="color_1"] .nav-header .hamburger.is-active .line {
    background: #fff !important;
  }
  [data-headerbg="color_1"] .nav-header .hamburger .line {
    background: var(--hslineargradient) !important;
  }
  [data-nav-headerbg="color_hs1"] .nav-header {
    background-color: #fff !important;
  }
  #main-wrapper.menu-toggle .header .header-content {
    padding-left: 4px;
  }
  .nav-control {
    right: 1.2rem;
  }
  .detail-card {
    width: 100% !important;
  }
  .detail-card .form-control {
    width: 50% !important;
  }
  .table-flow {
    overflow-x: scroll;
  }
  .setting-thres {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
  }
  .setting-thres .border {
    width: 100px !important;
  }
}
@media (min-width: 1200px) {
  .detail-card {
    width: 100% !important;
  }
  .detail-table {
    width: 100% !important;
  }
}

@media (min-width: 1000px) and (max-width: 1536px) {
  .nav-header {
    transition: all 0.3.2s ease;
  }
  /* .mm-active{
      background: #fff;
  }
  .mm-active .nav-text{
     color:#068d8a;
  } */
  /* [data-sidebar-style="overlay"] .menu-toggle .nav-header{
      width:25%;
  } */
  /* .metismenu li{
      border-bottom: 0.1px solid #ffff !important;
  } */
  /* .deznav {
      width: 25%;
  } */
  .hamburger.is-active .line:nth-child(1) {
    transform: translateY(4px) rotate(131deg);
  }
  .hamburger.is-active .line:nth-child(3) {
    transform: translateY(-5px) rotate(222deg);
  }
  .hamburger .line {
    margin-left: 0;
    margin-right: auto;
  }
  .firwall_overlay {
    right: 452px;
  }
}

.removecheckbox {
  display: none;
}

.radioHighLight:checked {
  color: var(--hsprimarycolor);
}

.spinner-border {
  width: 1rem !important;
  height: 1rem !important;
}

/* selected card styles */

.selectCardLabel:hover .selectCardBody {
  outline: 1px solid black;
}

.selectCardBody {
  transition: 4s all ease;
  position: relative;
}

.checkmark {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 0;
  right: 0;
  background-color: var(--hsprimarycolor);
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}

.checkmark::before {
  content: "✔";
  font-size: 11px;
  color: white;
  top: 5px;
  right: 5px;
  position: absolute;
}

/* selected card end styles */

/* stickyfooter_hs styles */

.stickyfooter_hs {
  position: fixed;
  bottom: 0;
  left: 230px;
  width: calc(100vw - 230px);
  height: 100px;
  background-color: white;

  box-shadow: 0 8px 16px 0px rgb(10 14 29 / 4%),
    0px 8px 64px 0px rgb(10 14 29 / 8%);

  z-index: 10;
}

.inputshowValue {
  color: var(--secondaryColor);
}

.changeInputControlBtns {
  cursor: pointer;
}

/* stickyfooter_hs end styles */

.displaycreatefooterform {
  box-shadow: 0 8px 16px 0px rgb(10 14 29 / 4%),
    0px 8px 64px 0px rgb(10 14 29 / 8%);
}

.radioIsCheckSelectedCard:checked {
  background-color: red;
}

/* dropDownContent */

.dropDownContent {
  z-index: 99999999;
  position: absolute;
  bottom: -300%;
  width: 100%;
  background-color: white;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* dropDownContent end */

.addtest {
  overflow: auto;
  overflow-x: hidden;
}

.addtest .dropdown-item {
  padding: 0 !important;
}

.aditemCustom {
  padding: 0 !important;
}

.dropdown > a .aditemCustom {
  padding: 10px !important;
  border: 1px solid black !important;
}

.addtest .dropdown-item {
  padding: 0 !important;
}

.tabsSelection.customTabsSelection > nav {
  width: 100% !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.tableCustomCircle {
  width: 25px;
  height: 25px;
  border-radius: 25px;
}

/*---------------------------- added by rahul---------------- */
.configuration-modal .modal-dialog {
  max-width: 1000px;
}

.configuration-modal .modal-dialog .radio {
  padding: 5px 0px;
  display: flex;
}

.configuration-modal .modal-dialog .radio label {
  margin-left: 10px;
}

.configuration-modal .modal-dialog .radio input {
  width: 20px;
  height: 20px;
}

.configuration-modal .modal-dialog .modal-content {
  border-radius: 0px;
}

.configuration-modal .form-control {
  height: 40px;
  border-radius: 0px;
  width: 50%;
}

.usage-graphs .card-body svg polyline {
  /* fill: #c7fffe !important; */
  fill-opacity: 1 !important;
  stroke: #0c928f !important;
}
.usage-graphs .card-body {
  display: flex;
  vertical-align: baseline;
}

.usage-graphs .card-body svg polyline:last-child {
  stroke-width: 1.5px !important;
}
.form-control {
  height: 40px;
}

@media only screen and (max-width: 1400px) {
  .form-control {
    height: 40px;
  }
}
.marketPlace-tabs .card-body {
  color: #606469;
  font-size: 14px;
}
.marketPlace-tabs .card-title {
  font-size: 18px;
  color: #32363c;
}
.marketPlace-tabs label {
  color: #32363c;
}
.marketPlaceInstance-tabs label {
  display: unset;
}

.marketPlaceInstance-tabs .tick,
.marketPlaceInstance-tabs .tick_default {
  top: unset;
}

.dedicatedCPU:checked ~ .tick_default {
  display: none;
}

.dedicatedCPU:checked ~ .tick {
  display: block;
}

.sharedCPU:checked ~ .tick_default {
  display: none;
}

.sharedCPU:checked ~ .tick {
  display: block;
}

.HighMemoryCPU:checked ~ .tick_default {
  display: none;
}

.HighMemoryCPU:checked ~ .tick {
  display: block;
}

.GPU:checked ~ .tick_default {
  display: none;
}

.GPU:checked ~ .tick {
  display: block;
}
.checkbox-location:checked ~ .tick {
  display: block;
}
.checkbox-location:checked ~ .tick_default {
  display: none;
}
.locations .img {
  height: 60px;
  width: 60px;
  background: rgba(231, 250, 236, 0.5);
  border: 1px solid #eeeeee;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  margin-bottom: 1rem;
}
.locations-label {
  min-width: 200px;
}
.instances-table-card img {
  width: 30px !important;
  height: 30px !important;
}
.instances-table-card .os {
  width: 45px;
  height: 45px;
}
.instances-table-card [data-test="datatable-entries"] {
  display: none !important;
}
.instances-table-card {
  box-shadow: none;
}
/* .instances-table-card [data-test="datatable"] {
                      border: 1px solid #EEEEEE;
                  } */
.instances-table-card [data-test="datatable-search"].col-md-6 {
  flex: 0 0 100%;
  max-width: 100% !important;
}
.instances-table-card [data-test="datatable-input"] {
  width: 100%;
  background-color: #f7f7f7;
}
.instances-table-card [data-test="datatable-input"] input {
  background: transparent;
  border: none;
  padding-left: 70px;
}
.instances-table-card [data-test="table-body"] .location {
  white-space: nowrap;
  font-size: 14px;
}
.instances-table-card h4,
.instances-table-card td {
  font-size: 16px;
}
.instances-table-card h4 {
  color: var(--hsprimarycolor);
}
.instances-table-card div {
  font-size: 14px;
}

.instacesClass > button.table-dropdown {
  padding: 0 !important;
}
.instances-table-card [data-test="datatable-input"] .form-control::before {
  content: "";
  background: url("https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSj3XfIpf-OOJRCcWz4iN2CU3qMKVVbj0p0bRvKNGVo1U9pk_8ZIlyR8pWreA");
  background-size: cover;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: -20px;
}
.instances-table-card [data-head="location"] {
  text-align: center;
}
.instances-table-card [data-head="os"] {
  text-align: center;
}
.instances-table-card
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background: var(--hsprimarycolor);
  border: var(--hsprimarycolor);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background: none;
  border-color: #e8e8e8;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e8e8e8;
}
.form-control:focus {
  border-color: var(--hsprimarycolor);
}
input[type="checkbox"]:checked:after {
  background: #068d8a;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background: var(--hsprimarycolor);
  border-color: var(--hsprimarycolor);
}
.instances-table-card a:hover {
  color: var(--hsprimarycolor);
}

/* end by rahul */

/* sachin */

.softwareSelectionPart #dropdown-basic-button {
  width: 100% !important;
}

.softwareSelectionPart .dropdown-menu {
  right: 0 !important;
}

/* end sachin */

.fancy-progress-bar {
  top: 0 !important;
  background: var(--hslineargradient);
}

.toasterCustom {
  margin-top: -15px;
  padding: 0;
  border-radius: 0;
  min-height: max-content;
  box-shadow: none;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.toasterCustomBody,
.toast-container {
  width: max-content;
}

.toasterCustomBody {
  margin: 0;
}

.alertFadein {
  animation: alertFadeinAnimtion 0.3s ease-in;
}

@keyframes alertFadeinAnimtion {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.alertFadeout {
  animation: alertFadeoutAnimtion 0.3s ease-in;
}

@keyframes alertFadeoutAnimtion {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* background-image: url('../images/hireserver/svg/gp.svg');   */

/* .backbroundoverlay{
   background-image: url('../images/hireserver/imgs/backoverlay.png'); 
   
   background-repeat: no-repeat;
    background-position: top right;
    position: relative;
  } */
.backbroundoverlay:before {
  position: fixed;
  content: "";
  top: -40px;
  /* bottom: 0;
    left: 0; */
  right: 0;

  width: 100%;
  min-height: 700px;

  background-image: url("../images/hireserver/imgs/backoverlay.png");
  opacity: 0.6;
  /* background-image: url('../images/hireserver/svg/gp.svg');   */
  background-repeat: no-repeat;
  background-position: top right;
  pointer-events: none;
}

.toasterCustomBodyAlert {
  /* margin-bottom: 100px; */
}

.toasterCustomError {
  background-color: transparent;
  box-shadow: none;
}




/* loader */
.loaderWaiting {
  position: fixed;
  top: 50%;
  left: calc(50% + 70px);
  width: 160px;
  height: 160px;
  margin: -80px 0px 0px -80px;
  background-color: transparent;
  border-radius: 50%;
  /* border: 2px solid #e3e4dc; */
}
.loaderWaiting:before {
  /* content: ''; */
  width: 164px;
  height: 164px;
  display: block;
  position: absolute;
  border: 2px solid #898a86;
  border-radius: 50%;
  top: -2px;
  left: -2px;
  box-sizing: border-box;
  clip: rect(0px, 35px, 35px, 0px);
  z-index: 10;
  animation: rotate infinite;
  animation-duration: 3s;
  animation-timing-function: linear;
}
.loaderWaiting:after {
  /* content: ''; */
  width: 164px;
  height: 164px;
  display: block;
  position: absolute;
  border: 2px solid #c1bebb;
  border-radius: 50%;
  top: -2px;
  left: -2px;
  box-sizing: border-box;
  clip: rect(0px, 164px, 150px, 0px);
  z-index: 9;
  animation: rotate2 3s linear infinite;
}
.hexagon-container {
  position: relative;
  top: 33px;
  left: 41px;
  border-radius: 50%;
}
.hexagon {
  position: absolute;
  width: 40px;
  height: 23px;
  background-color: #068d8a;
}
.hexagon:before {
  content: "";
  position: absolute;
  top: -11px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 11.5px solid #068d8a;
}
.hexagon:after {
  content: "";
  position: absolute;
  top: 23px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 11.5px solid #068d8a;
}
.hexagon.hex_1 {
  top: 0px;
  left: 0px;
  animation: Animasearch 3s ease-in-out infinite;
  animation-delay: 0.2142857143s;
}
.hexagon.hex_2 {
  top: 0px;
  left: 42px;
  animation: Animasearch 3s ease-in-out infinite;
  animation-delay: 0.4285714286s;
}
.hexagon.hex_3 {
  top: 36px;
  left: 63px;
  animation: Animasearch 3s ease-in-out infinite;
  animation-delay: 0.6428571429s;
}
.hexagon.hex_4 {
  top: 72px;
  left: 42px;
  animation: Animasearch 3s ease-in-out infinite;
  animation-delay: 0.8571428571s;
}
.hexagon.hex_5 {
  top: 72px;
  left: 0px;
  animation: Animasearch 3s ease-in-out infinite;
  animation-delay: 1.0714285714s;
}
.hexagon.hex_6 {
  top: 36px;
  left: -21px;
  animation: Animasearch 3s ease-in-out infinite;
  animation-delay: 1.2857142857s;
}
.hexagon.hex_7 {
  top: 36px;
  left: 21px;
  animation: Animasearch 3s ease-in-out infinite;
  animation-delay: 1.5s;
}
@keyframes Animasearch {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  15%,
  50% {
    transform: scale(0.5);
    opacity: 0;
  }
  65% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
    clip: rect(0px, 35px, 35px, 0px);
  }
  50% {
    clip: rect(0px, 40px, 40px, 0px);
  }
  100% {
    transform: rotate(360deg);
    clip: rect(0px, 35px, 35px, 0px);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
    clip: rect(0px, 164px, 150px, 0px);
  }
  50% {
    clip: rect(0px, 164px, 0px, 0px);
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(720deg);
    clip: rect(0px, 164px, 150px, 0px);
  }
}
@keyframes rotate3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hireserverBee {
  transition: all 2s ease;
  animation: moveAll 2s infinite;
}

@keyframes moveAll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(80vw);
  }
}

/* remove default select tag arrow */
.removeSelectArrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
/* remove default select tag arrow */

.CustomOption_hs {
  border-radius: 4px;
  /* background-color: red; */
}

.customDropSelect_hs .dropdown-item.active * div {
  background-color: #f4f5f8 !important;
  color: var(--secondaryColor);
}

.customDropSelect_hs .dropdown-item.active {
  background-color: #f4f5f8 !important;
  color: var(--secondaryColor);
}

.addtest .CustomOption_hs {
  border: none !important;
}

.customDropSelect_hs > a {
  color: var(--secondaryColor) !important;
  color: #000 !important;
  text-decoration: none;
}
.customDropSelect_hs > .dropdown-menu {
  padding: 0 !important;
  padding-top: 20px !important;
}

.customDropSelect_hs .dropdown-item:active {
  background-color: white !important;
  color: var(--secondaryColor) !important;
}

.customDropSelect_hs .dropdown-item:active * div {
  color: var(--secondaryColor) !important;
}

.svelte-1cmb9l6 > path {
  fill: #d9d9d9;
}

.red-tooltip + .tooltip > .tooltip-inner,
.red-tooltip + .tooltip > .tooltip-arrow {
  background-color: #f00;
}

/* .customDropSelect_hs .dropdown-item.active * div */

/* .tabledummbyhead{
  height: 0;
  width: 0;
} */

.w-100p {
  width: 100px !important;
}

.w-50p {
  width: 50px !important;
}

.w-75p {
  width: 75px !important;
}

.w-20p {
  width: 20px !important;
}

.w-30p {
  width: 30px !important;
}

.w-120p {
  width: 120px !important;
}

.w-150p {
  width: 150px !important;
}

.h-100p {
  height: 100px !important;
}

.h-50p {
  height: 50px !important;
}

.h-80p {
  height: 80px !important;
}

.h-75p {
  height: 75px !important;
}

.h-30p {
  height: 30px !important;
}

.h-200p {
  height: 200px !important;
}

.hs_underline {
  border-bottom: 1px solid;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.themeButton {
  background-color: #f4f4f4;
  width: 40px;
  border-radius: 50px;
}



/* dark mode test */

/* body {
  color: #222;
  background: #fff;
  font: 100% system-ui;
}
a {
  color: #0033cc;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #eee;
    background: #15202B;
  }

  body a {
    color: #809fff;
  }
} */

/* searchComponentCustomInput */
.searchComponentCustomInput {
  border-radius: var(--theme-border-radius);
}

.absCenter {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

/* routeCustomTabs */
.routerCustomTabsHeader {
  border-bottom: 0.2px solid var(--hsprimarycolor) !important;
  color: var(--hsprimarycolor) !important;
  border-top: none;
  border-left: none;
  border-right: none;
}

.routerCustomTabsHeaderMain:hover {
  border-bottom: 0.2px solid var(--hsprimarycolor) !important;
}

.routerCustomTabsHeader .routerLinkTag {
  color: var(--hsprimarycolor) !important;
}

/* auth alert start */
.redirectAuthLogo {
  width: 100px;
  height: 100px;
}

/* login alert */
.inputAlertVal {
  /* background: #ff000047; */

  border: 1px solid red;
}

.bg-dangerLite {
  background-color: rgb(255, 226, 224) !important;
}

.inputAlertVal:focus {
  border-color: red !important;
}

/* inputBorderDown */

.inputBorderDown {
  border: none;
  /* border-bottom: 3px solid var(--hsprimarycolor); */

  /* width: 30px; */
  text-align: center;
}

.inputBorderDown input {
  border: none;
  width: 100% !important;
  border: 1px solid lightgray;
  padding: 4px 8px;
  font-size: 21px;
}

.inputBorderDown > span {
  display: none;
}

/* remove input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hs_border_radius {
  border-radius: 8px;
}

.authModalAlert .modal-content {
  border-radius: 4px !important;
}

.hs_positionFull {
  left: 13px;
  top: 0;
  bottom: 0;
}
/* auth alert end */
/* 
.sideModalComponent{
  top: var(--headerHeight);
  right: 0;
} */

/* graph */
/* .hsGraph{
  width: 700px !important;
  height: 300px !important;
} */

.snapshotTable [data-test="datatable-entries"] {
  display: none !important;
}
/* headerCreateBtn */
.HeaderCreateButtonDropDownItem {
  padding: 0 !important;
}

/*  */
.datatable-input > input {
  font-size: 14px;
}

.retryBtn {
  padding: 10px 27px !important;
}

.hs-lightGreen {
  background-color: var(--bgLightGreen);
}

/* headerProfile */

.headerProfile {
  cursor: pointer;
}

.headerProfile > .dropdown-toggle {
  background: white !important;
}



.alertBackground{
  background-color: white !important;
}



/*  */
.snapshotTable .dataTables_info {
  display: none !important;
}

.viewConsoleDisable {
  background: #c9c4c4;
  pointer-events: none !important;
}

.instacesClass.viewConsoleDisable,
.viewBtnParent.viewConsoleDisable {
  background: transparent !important;
  color: #80808080;
}

.createButtonDropdown button .dropdown-toggle:before {
  content: "▾";
}

/* {
  background: transparent !important;
  color: #80808080;
} */

@media only screen and (min-width: 1191px) and (max-width: 1400px) {
  .deznav {
    width: 14rem;
  }
  .nav-header {
    width: 14rem;
  }

  .content-body {
    margin-left: 14rem;
  }

  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 18rem;
  }

  [data-sibebarbg="color_hs1"] .deznav {
    background-color: black !important;
  }
}
/* @media only screen and (max-width: 1199px){
  .nav-header .brand-title {
    display: block;
  }
} */

@media only screen and (max-width: 1193px) {
  /* [data-sidebar-style="full"] .header, [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 6rem;
  } */
}

@media only screen and (max-width: 1195px) {
  .hs_sidenav > .nav-header {
    /* width: 0; */
    /* padding-left: 6rem; */
  }
  .header.nav_header {
    /* padding-left: 5rem; */
  }
}

@media only screen and (max-width: 1199px) {
  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    padding-left: 5rem;
  }
  #main-wrapper.menu-toggle .header .header-content {
    padding-left: 0.3125rem;
  }
}

@media only screen and (max-width: 1199px) {
  .nav-header .brand-title {
    display: none;
  }
  #main-wrapper.menu-toggle .nav-header .brand-title {
    display: block;
  }

  #main-wrapper.menu-toggle .nav-header {
    width: 18rem !important;
  }
}

@media screen and (min-width: 119px) {
  .h-md-400p {
    height: 400px !important;
  }

  .h-md-524p {
    height: 524px !important;
  }

  .h-md-200p {
    height: 200px !important;
  }

  .h-md-100p {
    height: 100px !important;
  }

  .h-md-534p {
    height: 534px !important;
  }
}

.validFormInputSelect2 > div {
  /* border-color: var(--hsprimarycolor) !important;
  
*/
  border-color: grey !important;
}

.inputAlertValSelect2 > div {
  border-color: red;
}

@media screen and (min-width: 1000px) {
  .modalSupportHeight {
    height: 90vh;
    overflow: hidden;
  }
}

.deznav .metismenu > li.mm-active > a {
  color: white;
}



@media only screen and (max-width: 576px) {
  .nav_header {
    font-size: 15px !important;
  }

  .hamburger {
    width: 36px !important;
  }

  .themeButton {
    display: none;
  }

  /* .nav-control {
    right: 0 !important;
  } */
  .tenureListContainer {
    align-items: center;
    justify-content: center;
    gap: 40px !important;
  }
}

.badgeTenure {
  position: absolute;
  top: -14px;
  right: 0;
  left: 0;
  letter-spacing: 2px;
}

.hs-signin-btn .spinner-border {
  width: 1rem;
  height: 1rem;
}

.auth-form-1 .hs-signin-btn:hover {
  color: #edf0ea;
}

/* .tenureListContainer{
  gap:10px;
}
 */

.viewInstancesCreated.hs-signin-btn:hover {
  color: white;
}

/* custom table */
.customTable {
  border-spacing: 0 1.2rem;
  border-collapse: separate;
}

.customTable tr td {
  vertical-align: middle;
  border-bottom: 1px solid rgba(215, 218, 227, 0.3);
  padding: 15px 15px;
}

.customTable tr th {
  vertical-align: middle;
  border-bottom: 1px solid rgba(215, 218, 227, 0.3);
  padding: 15px 15px;
}

.customTable .checkboxTable {
  padding: 15px 5px;
  padding-left: 0;
}

.validFormInput {
  /* border-color: var(--hsprimarycolor) !important; */
  border-color: grey !important;
}

.hs-spinner-3 {
  width: 2rem !important;
  height: 2rem !important;
}

.billingFormParent.backbroundoverlay::before {
  position: absolute;
  min-height: 100%;
  top: 0 !important;
}

.z_index_99 {
  z-index: 99;
}

.z_index_9 {
  z-index: 9;
}

/* .removehtmltopdf{
  visibility: hidden !important;
} */
.passValidDot.passwordDotGreen {
  fill: var(--hsprimarycolor);
}

.passValidDot.passwordDotGrey {
  fill: grey;
}

/* code for sidebar */

/* .mm-active{
  position: relative;
}

.mm-active::after{
  position: absolute;
  content: '';
  top: 50px;
  right: -1px;
  height: 156px;
  width: 56px;
  border-top-right-radius: 80px;
  -moz-border-top-right-radius: 80px;
  -webkit-border-top-right-radius: 80px;
  box-shadow: 0 -46px 0 0 white;
}

.mm-active::before{
  position: absolute;
  content: '';
  top: -50px;
  right: -1px;
  height: 156px;
  width: 56px;
  border-top-right-radius: 80px;
  -moz-border-top-right-radius: 80px;
  -webkit-border-top-right-radius: 80px;
  box-shadow: 0 -46px 0 0 white;
} */

/* .boxlocation_hireserver:hover::before{
  content: 'NO avabile';
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right:0;
  z-index: 10000000;
  background-color: red !important;
} */

.hiddenShowMiniCard {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  background-color: #f8f8f8;
  pointer-events: auto !important;
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.hiddenShowMiniCard:hover {
  opacity: 1;
}



.select__control {
  border-radius: var(--borderRadiusHS) !important;
  border: 1px solid #d7dae3 !important;
}


.removeCardDisable {
  background: #f8f8f8 !important;
  pointer-events: none !important;
  overflow: hidden;
}

.circle_span {
  height: 30px;
  width: 30px;
  background-color: #d4d7da;
  border-radius: 50%;
  display: inline-block;
}

.notifyContianer {
  height: 90%;
  overflow: auto;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

.notifyContianer {
  overflow-y: overlay;
  overflow-x: hidden;
}

.notificationCard {
  border-bottom: 1px solid var(--borderBottom);
}

.borderFormSelect {
  border-color: grey !important;
}

.stateListBilling::placeholder {
  color: #000000;
}

.arrowInputDropdownparent {
  position: relative;
}
.arrowInputDropdown {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.7rem;
  color: #d7dae3;
}

/* select 2 */
.selectInputHs > div:first-child {
  border-radius: var(--borderRadiusHS) !important;
  border: 1px solid #d7dae3 !important;
}

/* datatable-pagination */

/* [data-test=datatable-pagination]{
  position: absolute !important;
  top: -5px !important;
  right: 0 !important;
}

[data-test=datatable-info]{
  display: none;
}

[data-test=pagination]{
  background-color: white;
  border-radius: var(--borderRadiusHS)  !important;
}

[data-test=pagination]:first-child span {
  font-size: 18px;
  font-weight: 700;
}

[data-test=pagination]:first-child  a[data-test=page-link] {
  border: none !important;
} */

.authincation-content {
  max-width: 470px;
  background: #fcfdf0;
}

.paymentInnerCard {
  max-width: 800px;

}

.RouteCustomTabClass::-webkit-scrollbar {
  width: 10px !important;
}

.RouteCustomTabClass::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
.RouteCustomTabClass::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0);
}

/* Handle on hover */
.RouteCustomTabClass::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0);
}

/* skeleton login */
.loginSkeleton {
  width: 500px;
  height: 540px;

  transform: translateX(-200px);
}

/* timelineParentContainer */

.timelineParentContainer > div:last-child .iconTime > .timelineBorder {
  display: none !important;
  /* display: block !important; */
}


.btn-renwal {
  background: transparent !important;
  border: 1px solid var(--hsprimarycolor);
  color: black;
}

/* logoHireClass */

.logoHireClass {
  position: fixed;
  top: 50px;
  left: 50px;
}

.passwordBoxHS {
  z-index: 800;
  position: absolute;
  top: -147px;
  right: 0;

  background-color: white;
  padding: 1rem;

  font-size: 12px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
}

.passwordBoxHS_len {
  top: -80px !important;
}

.passwordBoxHSWHiteBox {
  width: 10px;
  height: 10px;

  background-color: white;
  position: absolute;
  bottom: -5px;
  left: 45%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  transform: rotate(45deg);

  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.removeDeafaultdropdown {
  color: black !important;
  background-color: transparent !important;
  border-color: none !important;

  padding: 0 !important;
  border-radius: 0 !important;
  font-weight: 0 !important;
  font-size: 1rem;
}

.removeDeafaultdropdown.dropdown-toggle:after {
  content: none !important;
  margin: 0 !important;
}

.removeDefaultDropMenu {
  max-height: 200px;
  overflow: auto;
}

/* individual  */
.individualpaymetinfoaddHeight {
  min-height: min-content;
}



.individualpaymetinfoaCloseBtn {
  position: absolute;
  bottom: 5%;
  right: 19%;
}

.addHeightAnimation {
  height: max-content !important;
  transition: all 0.5s ease;
}

.isHeightAnimation {
  max-height: 500px;
  transition: all 0.2s ease;
}

/* #individualpaymetinfoId{
  overflow-y: auto !important;
} */

.vmDetailsDropdown > button {
  padding: 0 !important;
}

/* dropdownlist actions */

/* [data-machineaction="4"] svg g > path{
  fill: var(--hsprimarycolor);
}

[data-machineaction="6"] svg g > path{
  fill: rgb(188, 46, 46);
}

[data-machineaction="7"] svg g > path{
  fill: #199ce7;
}

[data-machineaction="8"] svg g > path{
  fill:orange;
}

[data-machineaction="10"] svg g > path{
  fill:red;
} */

.listactioncontainer svg g > path {
  fill: rgba(0, 0, 0, 0.773);
}

.listactioncontainer .pointerEventNone  svg g > path {
  fill: grey;
}

.billingiconcircle{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -6%;
  text-align: center;

  z-index: 9999;
  

  width: 50px;
  height: 50px;

  background: var(--hslineargradient);
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.585);
-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.529);
box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.484);
}

.billingiconcircle > span{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 25px;
  color: white;
}

.hs_maxContent{
  min-width: max-content;
}

.billingFormParent  > div{
  width: 100%;
}

.editDesButton{
  position: absolute;
  top: 0;
  right:0;
  cursor: pointer;
}

.dropdown-item .dropdown-item.ai-icon:active > span{
  color: black !important;
}
/* pagination start */
.pagination {
  gap: 10px;
}

.pagination .active.page-item a{
  border-radius: 50%;
}

.pagination li:nth-child(1),.pagination li:last-child{
 
  border-radius: 50%;
  width: 39px;
    border: 1px solid var(--borderBottom) !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

 
  
}

.pagination li:nth-child(1) .page-link,.pagination li:last-child .page-link{
  border: none !important;

}


.page-link{
  border: 0px !important;
  display: flex;
  justify-content: center;
  border: 1px solid var(--borderBottom) !important;
  border-radius: 50%;
}

/* pagination end */

.border-radius-0{
  border-radius: 0 !important;
}

[data-test=datatable-head] tr th{
  white-space: nowrap;
}

.needarrowtable{
  position: relative;
}

[data-test=datatable-head] tr th.needarrowtable::after{
  content: url('../images/hireserver/imgs/updownarrow.svg');
  font-size: 5px;
  width: 5px !important;
  height: 5px !important;
  max-width: 5px;
  top:32%;
  position: absolute;
  
}



.updownContainerTableIcon img{
  width: 20px !important;
}

.activeselectcardStyle{
  box-shadow: var(--box-shadow);
  background-color: var(--bgLightGreen);
}

.HeaderCreateButtonDropDownItem .dropdown-item:active p{
  color: black !important;
}

.hasBackButton{
  width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: all .2s ease;
    font-size: 22px;

}

.hasBackButton:hover{
  background-color: var(--borderBottom);
}

.mm-active .ai-icon > i{
  color: var(--hsprimarycolor) !important;
}
.menu-toggle .mm-active .ai-icon > i{
  color: #d7f1f1 !important;
}

.deznav .metismenu > li.mm-active > a{
  background-color: white;
  color: var(--hsprimarycolor) !important;
}

.labelInputComponent::placeholder{
  color: var(--placeHolderColor) !important;
  font-size: var(--placeHolderFontSize);
}


.h-400p{
  min-height: 400px;
}

.scriptAreatable [data-test="datatable-entries"]{
  display: none !important;
}

.areaScriptModalMax{
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.removeLinkStyle{
  text-decoration: none !important;
}

.billingpContainer > p {
  line-height: 4;
}

.instanceListTable.duetable [data-test="datatable-info"]{
  display: none;
}
  .instanceListTable.duetable [data-test="datatable-pagination"]{
  display: none;
}

.customInputSearch{
  padding-left: 4rem !important;
}

.w-130p{
  width: 130px;
}

.scriptcardtemplate:hover{
  text-decoration: none;
}

.disableborder{
  border: 1px solid;
  border-radius: var(--borderRadiusHS);
}

.cronjobtoggle .react-toggle--checked .react-toggle-track{
  background-color: var(--hsprimarycolor);
} 

.hs-boxshadow-1{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.text-hs-break{
  max-width: 100%;
white-space: break-spaces;
}

.inputsearchtable:focus,.inputsearchtable:hover{
  background-color: var(--hs-grey) !important;
}

.hs-grey-btn{
  background: #646464 !important;
}

.addresstoperror{
  font-size: 12px;
  position: absolute;
    left: 50%;
    top: -1%;
    transform: translateX(-50%);
}

.billingFromDiv{
  position: relative;
}

@import "./darkMode.css";
@import "./MiniLaptop.css";
@import "./Tablet.css";
@import "./Mobile.css";
@import "./OtherMedia.css";

