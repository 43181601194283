.billingFromDiv{
    width: 600px;
}



.stateListBilling{
   
}

