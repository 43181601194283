.boxlocation_hireserver {
    position: relative;
    min-width: 150px;
    height: 150px;
    border: 0.6000000238418579px solid #D6D6D6;
    margin: 10px;
}

.checkbox_location_hireserver {
    position: absolute;
    top: 18px;
    right: 5%;
    width: 20px;
    height: 20px;
    border: none;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    pointer-events: none;
}


.tick_defaultMini {
    position: absolute;
    top: 18px;
    right: 5%;
    width: 20px;
    height: 20px;
    display: block;
    pointer-events: none;
    border: 0.6000000238418579px solid #D6D6D6;
    border-radius: 10px;
}

.checkbox_location_hireserver:checked ~ .tick_defaultMini {
    display: none;
}

.checkbox_location_hireserver:checked ~ .tick_Mini {
    display: block;
}

.tick_Mini {
    position: absolute;
    top: 18px;
    right: 5%;
    width: 20px;
    height: 20px;
    display: none;
    border-radius: 10px;
    background: #24B7AF 0% 0% no-repeat padding-box;
    font-size: 13px;
    color: white;
}