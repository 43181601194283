.dropDownWithImgGroupContainer,.inputContainerDropDownWithImgGroup{
   position: relative;
   
}

.dropDownWithImgGroupBox{
    position: absolute;
    bottom: -150%;
    left: 0;
    right: 0;

    width: 100%;
    max-height: 500px;
    overflow-y: auto;
}

.overlayImgOptionDropDown{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
}

.arrowDropDownWithImg{
    position: absolute;
    right: 15px;
    top: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputDropDownWithImgGroupPlaceholder{
    color: var(--placeHolderColor) !important;
    font-size: var(--placeHolderFontSize);
}