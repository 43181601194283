.buttonOptions{
    min-width: 80px;
    background: var(--hslineargradient);
    color: white;
    
    /* border-radius: 18px; */
    border-radius: var(--borderRadiusHS);
}

.toggleButtonOps{
    /* background: var(--hsprimarycolor)!important; */
    background: var(--hslineargradient) !important;;

}

.textbuttonOptions{
    border-right: 1px solid white;
}

.BOItem{
    padding: 0 0.5rem;
}

.dropdownbuttonoption{
    cursor: pointer;
    background: var(--hslineargradient);
}
.toggleButtonOps::after,.toggleButtonOps::before{
    content: '' !important;
    margin: 0;
}