.sideModalNoRoute {
  width:370px;
  height: 100vh;

  background-color: white;

  position: fixed;
  top: 0;
  

  z-index: 11;

  box-shadow: 0 28px 28px rgb(0 0 0 / 7%);

  transition: all .7s ease;
}

.closeSideModalNoRoute{
    position: absolute;
    top: 18px;
    right: 18px;
}